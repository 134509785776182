import React from "react"
import { Jumbotron, Tab, ListGroup, Row, Card } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Img from "gatsby-image"

const listStyles = {
  paddingLeft: 20,
  fontWeight: "bold",
  fontFamily: "Helvetica",
}

const headerStyles = {
  margin: 20,
  fontWeight: "bold",
  fontFamily: "Helvetica",
}

const listStyles2 = {
  margin: 20,
  fontFamily: "Helvetica",
}

const imageStyles = {
  margin: 20,
}

const PrintHelp = () => {
  const { t, i18n } = useTranslation()

  const currentLocale = i18n.language

  let appendImage = ""

  if (currentLocale === "zh-TW") {
    appendImage = "zh"
  }

  const data = useStaticQuery(graphql`
    query {
      epson1: file(relativePath: { eq: "EPSON_T82II_III.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      epson2: file(relativePath: { eq: "EPSON_M10_M30.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      star1: file(relativePath: { eq: "STAR_TSP654.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      star2: file(relativePath: { eq: "STAR_TSP100.png" }) {
        childImageSharp {
          fluid(maxWidth: 225, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot0: file(relativePath: { eq: "screenshot_0.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot0zh: file(relativePath: { eq: "screenshot_0_zh.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot1: file(relativePath: { eq: "screenshot_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot2: file(relativePath: { eq: "screenshot_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot3: file(relativePath: { eq: "screenshot_3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot4: file(relativePath: { eq: "screenshot_4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot5: file(relativePath: { eq: "screenshot_5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot6: file(relativePath: { eq: "screenshot_6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot7: file(relativePath: { eq: "screenshot_7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot8: file(relativePath: { eq: "screenshot_8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot9: file(relativePath: { eq: "screenshot_9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot10: file(relativePath: { eq: "screenshot_10.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot11: file(relativePath: { eq: "screenshot_11.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot12: file(relativePath: { eq: "screenshot_12.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot13: file(relativePath: { eq: "screenshot_13.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot14: file(relativePath: { eq: "screenshot_14.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot15: file(relativePath: { eq: "screenshot_15.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot16: file(relativePath: { eq: "screenshot_16.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot17: file(relativePath: { eq: "screenshot_17.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot18: file(relativePath: { eq: "screenshot_18.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot19: file(relativePath: { eq: "screenshot_19.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot20: file(relativePath: { eq: "screenshot_20.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot21: file(relativePath: { eq: "screenshot_21.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot22: file(relativePath: { eq: "screenshot_22.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot23: file(relativePath: { eq: "screenshot_23.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot24: file(relativePath: { eq: "screenshot_24.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot25: file(relativePath: { eq: "screenshot_25.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot26: file(relativePath: { eq: "screenshot_26.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot27: file(relativePath: { eq: "screenshot_27.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      screenshot28: file(relativePath: { eq: "screenshot_28.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  console.log({
    appendImage,
    fluid: data[`screenshot0${appendImage}`].childImageSharp.fluid,
  })
  return (
    <div>
      <br />
      <Jumbotron>
        <div className="col-lg-8">
          <Card style={imageStyles}>
            <h3 style={headerStyles}>{t("print-1")}</h3>
            <ul>
              <li style={listStyles}>
                <h5>{t("print-3")}</h5>
              </li>
              <Img
                fluid={data[`screenshot0${appendImage}`].childImageSharp.fluid}
                style={imageStyles}
              />
              <li>
                <h5 style={listStyles2}>{t("print-4")}</h5>
              </li>
              <hr />
              <li>
                <h4 style={listStyles}>{t("print-5")}</h4>
              </li>
              <li style={listStyles2}>
                <h5>{t("print-6")}</h5>
              </li>
              <li style={listStyles2}>
                <h5>{t("print-7")}</h5>
              </li>

              <Img
                fluid={data.screenshot1.childImageSharp.fluid}
                style={imageStyles}
              />
              <li>
                <h5 style={listStyles2}>{t("print-8")}</h5>
              </li>
              <hr />
              <li>
                <h4 style={listStyles}>{t("print-9")}</h4>
              </li>
              <Img
                fluid={data.screenshot2.childImageSharp.fluid}
                style={imageStyles}
              />
              <li>
                <h5 style={listStyles2}>{t("print-10")}</h5>
              </li>
              <li>
                <h5 style={listStyles2}>{t("print-11")}</h5>
              </li>
              <li>
                <h5 style={listStyles2}>{t("print-12")}</h5>
              </li>

              <hr />
              <li>
                <h4 style={listStyles}>{t("print-ios1")}</h4>
              </li>
              <li>
                <h5 style={listStyles2}>{t("print-ios2")}</h5>
              </li>
              <li>
                <h5 style={listStyles2}>{t("print-ios3")}</h5>
              </li>
              <li>
                <h5 style={listStyles2}>{t("print-ios4")}</h5>
              </li>
              <Img
                fluid={data.screenshot10.childImageSharp.fluid}
                style={imageStyles}
              />
              <hr />
              <li>
                <h4 style={listStyles}>{t("print-ios5")}</h4>
              </li>
              <li>
                <h5 style={listStyles2}>{t("print-ios6")}</h5>
              </li>
              <li>
                <h5 style={listStyles2}>{t("print-ios7")}</h5>
              </li>
              <Img
                fluid={data.screenshot14.childImageSharp.fluid}
                style={imageStyles}
              />
              <li>
                <h5 style={listStyles2}>{t("print-ios8")}</h5>
              </li>
              <Img
                fluid={data.screenshot15.childImageSharp.fluid}
                style={imageStyles}
              />
              <li>
                <h5 style={listStyles2}>{t("print-ios9")}</h5>
              </li>
              <hr />
              <Img
                fluid={data.screenshot11.childImageSharp.fluid}
                style={imageStyles}
              />
              <li>
                <h5 style={listStyles2}>{t("print-14")}</h5>
              </li>
              <hr />
              <li>
                <h5 style={listStyles2}>{t("print-13")}</h5>
              </li>
            </ul>
          </Card>
        </div>
        <br />
        <h3>{t("print-13")}</h3>
        <Tab.Container
          id="list-group-tabs-example"
          defaultActiveKey="#link1"
          fluid
        >
          <div
            className="col-xs-12"
            style={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <ListGroup horizontal>
              <ListGroup.Item
                action
                href="#link1"
                variant="secondary"
                style={headerStyles}
              >
                EPSON T82II/III
                <Img
                  fluid={data.epson1.childImageSharp.fluid}
                  style={imageStyles}
                />
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#link2"
                variant="secondary"
                style={headerStyles}
              >
                EPSON M10/M30
                <Img
                  fluid={data.epson2.childImageSharp.fluid}
                  style={imageStyles}
                />
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#link3"
                variant="secondary"
                style={headerStyles}
              >
                STAR TSP654
                <Img
                  fluid={data.star1.childImageSharp.fluid}
                  style={imageStyles}
                />
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#link4"
                variant="secondary"
                style={headerStyles}
              >
                STAR TSP100
                <Img
                  fluid={data.star2.childImageSharp.fluid}
                  style={imageStyles}
                />
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="col-lg-8">
            <Card>
              <Tab.Content>
                <Tab.Pane eventKey="#link1">
                  <div>
                    <h3 style={headerStyles}>EPSON T82II/III</h3>
                    <ul>
                      <li>
                        <h5 style={listStyles}>{t("epsont-15")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot5.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("epsont-1")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-2")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-3")}</h5>
                      </li>
                      <li style={listStyles2}>(Video Here)</li>
                      <hr />
                      <li>
                        <h5 style={listStyles2}>{t("epsont-4")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot4.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("epsont-5")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-6")}</h5>
                      </li>

                      <li>
                        <h5 style={listStyles2}>{t("epsont-7")}</h5>
                      </li>
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("epsont-8")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-9")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot7.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles}>{t("epsont-10")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot8.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles}>{t("epsont-11")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot9.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("epsont-12")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-13")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot6.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("epsont-14")}</h5>
                      </li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="#link2">
                  <div>
                    <h3 style={headerStyles}>EPSON M10/M30</h3>
                    <ul>
                      <li>
                        <h5 style={listStyles}>{t("epsont-15")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot12.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("epsont-1")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-2")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-3")}</h5>
                      </li>
                      <li style={listStyles2}>(Video Here)</li>
                      <hr />
                      <li>
                        <h5 style={listStyles2}>{t("epsonm-1")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsonm-2")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot13.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("epsonm-3")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot16.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles}>add video</h5>
                      </li>
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("epsont-5")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-6")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-7")}</h5>
                      </li>
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("epsont-8")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-9")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot7.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("epsont-10")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot8.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("epsont-11")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot9.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("epsont-12")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("epsont-13")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot6.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles}>{t("epsont-14")}</h5>
                      </li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="#link3">
                  <div>
                    <h3 style={headerStyles}>STAR TSP654</h3>
                    <ul>
                      <li>
                        <h5 style={listStyles}>{t("epsont-15")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot20.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star654-1")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("star654-2")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("star654-3")}</h5>
                      </li>
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("star654-4")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot18.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star654-5")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot19.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <hr />
                      <li>
                        <h5 style={listStyles2}>{t("star654-6")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot24.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star654-7")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot25.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <hr />
                      <li>
                        <h5 style={listStyles2}>{t("star654-8")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot26.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star654-9")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot27.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <Img
                        fluid={data.screenshot28.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star654-10")}</h5>
                      </li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="#link4">
                  <div>
                    <h3 style={headerStyles}>STAR TSP100</h3>
                    <ul>
                      <li>
                        <h5 style={listStyles}>{t("epsont-15")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot17.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star100-1")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("star100-2")}</h5>
                      </li>
                      <li>
                        <h5 style={listStyles2}>{t("star100-3")}</h5>
                      </li>
                      <hr />
                      <li>
                        <h5 style={listStyles}>{t("star100-4")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot18.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star100-5")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot19.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <hr />
                      <li>
                        <h5 style={listStyles2}>{t("star100-6")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot21.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star100-7")}</h5>
                      </li>
                      <Img
                        fluid={data.screenshot22.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <Img
                        fluid={data.screenshot23.childImageSharp.fluid}
                        style={imageStyles}
                      />
                      <li>
                        <h5 style={listStyles2}>{t("star100-8")}</h5>
                      </li>
                    </ul>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Card>
          </div>
          {/* </Row> */}
        </Tab.Container>
      </Jumbotron>
    </div>
  )
}

export default PrintHelp
