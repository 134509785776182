import React from "react"
import Navbar2 from "../components/Navbar2"
import PrintHelp from "../components/PrintHelp"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import "../static/style.css"

const Print = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Navbar2 />
      <PrintHelp />
    </I18nextProvider>
  )
}

export default Print
